/* proxiva nova font */
@import url("https://use.typekit.net/nzg4lfi.css");

html {

    /* 62.5% of 16px browser font size is 10px */
    font-size: 62.5%;
}

body {
    padding: 0;
    margin: 0;
    font-family: proxima-nova, sans-serif;
}

p, th, td, a, input, .form-select, textarea, .btn {
    font-family: proxima-nova, sans-serif;
    font-size: 2.5rem;
    line-height: 3.7rem;
    color: #8A8A8A;
}

h1, h2, h3, h4, h5, h6 {
    font-family: proxima-nova, sans-serif;
}

h1 {
    font-size: 4.7rem;
    line-height: 5.7rem;
}

h2 {
    font-size: 3.7rem;
    line-height: 4.5rem;
}

h3 {
    font-size: 3rem;
    line-height: 3.7rem;
}

h4 {
    font-size: 2.4rem;
    line-height: 2.8rem;
}

h5 {
    font-size: 2.2rem;
    line-height: 2.6rem;
}

h6 {
    font-size: 2rem;
    line-height: 2.4rem;
}

.font-light {
    font-weight: 100;
}

.heading-test {
    color: red;
}

.navbar-nav li a img {
    width: auto;
    margin: auto;
    max-width: 100%;
}

a.navbar-brand {
    margin-right: 0;
    margin-bottom: 2rem;
    padding: 2rem;
    text-align: center;
}

.side-bar .navbar {
    background-color: #000 !important;
}

.navbar-expand-md .navbar-collapse {
    align-items: flex-start;
}

.navbar-nav li a {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    opacity: .4;
    margin-bottom: 1.5rem;
}

.navbar-nav li a:hover, .navbar-nav li a:focus, .navbar-nav li a.active {
    opacity: 1;
}

.navbar-nav li a span {
    text-transform: uppercase;
    margin-top: 1rem;
    font-size: 1.7rem;
    line-height: 2.1rem;
}

.navbar-toggler-icon {
    width: 3.5rem;
    height: 3.5rem;
}

/* typography css */
.main-heading {
    font-size: 4.7rem;
    line-height: 5.7rem;
    color: #000000;
    font-family: proxima-nova, sans-serif;
    font-weight: 100;
    font-style: normal;
}

/* main-content section css start from here */
.main-content .container, .footer-section .container {
    max-width: 90%;
}

.main-content {
    height: 100vh;
    display: flex;
    align-items: center;
}

.team-member h3 {
    font-size: 3rem;
    line-height: 3.7rem;
    color: #000000;
    font-family: proxima-nova, sans-serif;
    font-weight: 300;
    font-style: normal;
    margin-top: 2rem;
}

.team-member h3 span {
    font-weight: 100;
    opacity: .52;
}

.member-profile-pic {
    background: #E9E9E9;
    border-radius: 100%;
    height: 21.2rem;
    width: 21.2rem;
}

img.profile-pic {
    border: solid .3rem #000000;
    border-radius: 100%;
}

.team-member {
    margin-top: 3rem;
}

.team-page .footer-section .cta-btn .prev-btn {
    opacity: 0;
}

.popup-cta-btn a {
    border: none;
}

.team-member-contacts {
    list-style: none;
    padding-left: 0;
    display: inline-flex;
    width: 100%;
}

.team-member-contacts li + li {
    padding-left: 3rem;
}

.disable-tm .team-member-contacts {
    opacity: .18;
}

/* your glass page css start from here */
.bg-image {
    position: absolute;
    z-index: -1;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
}

img.bg-img.img-fluid {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.estimate-content-section.collapsible-shutter {
    width: 18rem;
    height: 13rem;
    transition: width .3s, height .3s;
}

.estimate-content-section {
    transition: width .3s, height .3s;
    width: 80rem;
    height: 60rem;
}

.rgt-sec-cta a img {width: 100%;}

.estimate-content-section.collapsible-shutter .shutter-sec p {
    display: block;
    margin-bottom: 0;
}

.estimate-content-section.collapsible-shutter .shutter-sec {
    background: linear-gradient(#F0EDED, #CCC8C8);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;
    transition: 0.3s ease-in-out;
}

.colapsible-icon img {
    transition: 0.3s ease-in-out;
    width: 3rem;
}

.estimate-content-section.collapsible-shutter .colapsible-icon img {
    transform: rotate(175deg);
    transition: 0.3s ease-in-out;
}

.extra-btn-sec {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}

/* estimate sec css start from here */
.estimate-content-section {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 2.8rem;
    opacity: 1;
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    background-color: rgba(255, 255, 255, 1);
}

ul.estimate-heading-list {
    padding: 0;
    list-style: none;
}

ul.estimate-heading-list li:nth-child(odd) {
    background: transparent linear-gradient(270deg, #F0EDED 0%, #CCC8C8 100%) 0% 0% no-repeat padding-box;
}

ul.estimate-heading-list li {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.estimate-content-section h3 {
    font-size: 2.7rem;
    line-height: 3rem;
    font-family: proxima-nova, sans-serif;
    color: #000000;
    font-weight: 700;
}

.estimate-content-section p {
    color: #000000;
    font-size: 2rem;
    line-height: 2rem;
}

.optn-btn {
    background: transparent;
    border-radius: 2.5rem;
    color: #000;
    font-size: 2rem;
    line-height: 2.5rem;
    font-family: proxima-nova, sans-serif;
    padding: .5rem 1.5rem;
    min-width: 12rem;
    border: 2px solid #0000;
}

.optn-btn:hover, .optn-btn.active {
    background: transparent;
    border: 2px solid #000;
    color: #000;
}

.estimate-content-section {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 2.8rem;
    opacity: 1;
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    background-color: rgba(255, 255, 255, 1);
    padding-bottom: 4rem;
    position: relative;
    overflow: hidden;
}

ul.estimate-heading-list {
    padding: 0;
    list-style: none;
}

ul.estimate-heading-list li:nth-child(even) {
    background: transparent linear-gradient(90deg, #F0EDED 0%, #CCC8C8 100%) 0% 0% no-repeat padding-box;
}

ul.estimate-heading-list li {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.estimate-content-section h3 {
    font-size: 2.7rem;
    line-height: 3rem;
    font-family: proxima-nova, sans-serif;
    color: #000000;
    font-weight: 700;
}

.estimate-content-section p {
    color: #000000;
    font-size: 2rem;
    line-height: 2.5rem;
}

.optn-btn {
    background: transparent;
    border-radius: 2.5rem;
    color: #000;
    font-size: 2rem;
    line-height: 2.5rem;
    font-family: proxima-nova, sans-serif;
    padding: .5rem 1.5rem;
    min-width: 12rem;
    border: 2px solid #0000;
}

.optn-btn:hover, .optn-btn.active {
    background: transparent;
    border: 2px solid #000;
    color: #000;
}

.ecs-scrolling-description-sec {
    padding-left: 3rem;
    padding-right: 3rem;
    height: 30rem;
    overflow-y: auto;
    margin-right: 3rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.ecs-scrolling-description-sec h5 {
    font-size: 2rem;
    color: #000;
    line-height: 2.4rem;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.ecs-scrolling-description-sec h3 {
    font-size: 2.5rem;
    line-height: 2.7rem;
    font-weight: 400;
}

.main-wrap-sec {
    margin-bottom: 1.5rem;
}

.ecs-scrolling-description-sec a {
    background: #000000;
    padding: 1rem;
    height: 3rem;
    width: 3rem;
    border-radius: 100%;
    color: #fff;
}

.ecs-scrolling-description-sec.active a, .ecs-scrolling-description-sec a:hover, .ecs-scrolling-description-sec a:active, .ecs-scrolling-description-sec a:focus {
    background: #62BBBC;
}

.rgt-sec-cta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.rgt-sec-cta a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
}

.add-btn {
    background-color: #DD8F41 !important;
    border-radius: 3rem !important;
    width: auto !important;
    height: 4rem !important;
    font-family: proxima-nova, sans-serif;
    font-size: 2rem;
    padding: 1rem 2rem !important;
    border: none;
    margin-left: 1rem;
    color: #fff;
}

.ecs-scrolling-description-sec ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 3rem;
}

.ecs-scrolling-description-sec ul li {
    font-size: 2rem;
    font-weight: 100;
    color: #000;
}

.brand-list {
    margin: 4rem 0;
}

.total-estimate {
    background: transparent linear-gradient(90deg, #F0EDED 0%, #CCC8C8 100%) 0% 0% no-repeat padding-box;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: .5rem;
}

.price-count span {
    font-weight: 300;
}

.total-btn {
    width: auto !important;
    height: auto !important;
    border: none;
    border-radius: 1rem !important;
    font-size: 2rem;
    padding: 1rem 2rem !important;
}

.price-count h5 {
    margin-bottom: .5rem;
}

.shutter-sec {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 18rem;
    height: 13rem;
}

.colapsible-icon {
    width: 9rem;
    height: 15rem;
    background: transparent linear-gradient(294deg, #F0EDED 0%, #CCC8C8 100%) 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: normal;
    transform: rotate(45deg);
    padding-left: 1rem;
    position: absolute;
    right: -3rem;
    bottom: -6rem;
}

.estimate-content-section.colapsible {
    width: 18rem;
    height: 13rem;
}

.estimate-content-section.colapsible .colapsible-icon img {
    transform: rotate(175deg);
}

.estimate-content-section.colapsible .shutter-sec {
    background: transparent linear-gradient(270deg, #F0EDED 0%, #CCC8C8 100%) 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shutter-sec p {
    display: none;
}

.estimate-content-section.colapsible .shutter-sec p {
    display: block;
    padding: 1.5rem;
}

/* footer css start from here */
.footer-section {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
        z-index: 2;
}

.contact-no, .cta-btn, .popup-cta-btn, .pagination-sec {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 2.8rem;
    opacity: 1;
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.contact-no, .cta-btn {
    justify-content: center;
}

.chatbot-icon {
    background-color: #fff;
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-no, .cta-btn {
    width: 50.3rem;
    height: 11.1rem;
}

.contact-no {
    width: auto;
    padding-left: 4rem;
    padding-right: 4rem;
}

.footer-cta-btn .btn:focus {
    box-shadow: none !important;
}

.main-content .container, .main-content .container-fluid {
    padding-left: 4rem;
    padding-right: 4rem;
}

.footer-section .container {
    padding-left: 0;
    padding-right: 0;
}


.cta-btn, .popup-cta-btn {
    width: 30rem;
    height: 11rem;
}

.popup-cta-btn img {
    width: 6rem;
}

.cta-btn .next-btn {
    color: #fff;
}

.cta-btn .btn {
    font-size: 2.8rem;
    line-height: 3.4rem;
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    border: none;
    border-radius: 1.8rem;
    padding: 2rem 3rem;
    text-transform: uppercase;
    position: relative;
    background-color: transparent;
}


.button-lottie-ani > div {
    width: auto !important;
    height: 160px !important;
}

.cta-btn .btn.prev-btn {
    font-weight: 400;
    color: #000;
}

.button-lottie-ani {
    position: relative;
}

.button-lottie-ani .btn {position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);color: #fff;}

.contact-no h3 {
    letter-spacing: 0px;
    color: #000000;
}

.contact-no h3 {
    font-size: 3rem;
    line-height: 3.7rem;
    font-family: proxima-nova, sans-serif;
    font-weight: 100;
    text-align: left;
    margin-bottom: 0;
}

.contact-no h3 a {
    color: #000;
    text-decoration: none;
    padding-left: 1rem;
    font-weight: 400;
}

.popup-cta-btn a:active, .popup-cta-btn a:focus, .popup-cta-btn a:hover {
    border: transparent;
}

.pagination-sec {
    position: absolute;
    right: 5%;
    top: 30%;
    width: 8.5rem;
    height: 36.5rem;
    padding: 2rem 1rem;
}

.pagination-scrollable {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

ul.pagination-list {
    padding-left: 0;
    margin-bottom: 0;
}

.pagination-sec ul.pagination-list li {
    padding-bottom: 2rem;
}

.pagination-sec ul.pagination-list li a {
    background: rgba(255, 255, 255, .5);
    width: 4.6rem;
    height: 4.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-size: 2rem;
    line-height: 2.4rem;
    border-radius: 50%;
    margin: auto;
    text-align: center;
}

.pagination-sec ul.pagination-list li a:hover {
    background: rgba(255, 255, 255, 1);
}

/* scroll bar modification css */

/* width */::-webkit-scrollbar {
    width: .5rem;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 .5rem grey;
    border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #000000;
}

.ecs-scrolling-description-sec, .pagination-scrollable {
    scrollbar-width: thin;
    scrollbar-color: #000 #bbb;
}

.ecs-scrolling-description-sec::-webkit-scrollbar, .pagination-scrollable::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
}

.ecs-scrolling-description-sec::-webkit-scrollbar-track, .pagination-scrollable::-webkit-scrollbar-track {
    background-color: #bbb;
}

.ecs-scrolling-description-sec::-webkit-scrollbar-thumb, .pagination-scrollable::-webkit-scrollbar-thumb {
    background-color: #000;
}

.ecs-scrolling-description-sec::-webkit-scrollbar-track, .ecs-scrolling-description-sec::-webkit-scrollbar-thumb, .pagination-scrollable::-webkit-scrollbar-track, .pagination-scrollable::-webkit-scrollbar-thumb {
    border-radius: 10rem;
}

/* modal css start from here */

/*.fade:not(.show) {
opacity: 1;
}
.modal {
display: block;
}*/

.welcome-overlay.modal {
    display: block;
}

.welcome-overlay.fade:not(.show) {
    opacity: 1;
}

.blur-overlay {
    opacity: 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, .5);
}



.about-video-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-scrolable-area {
    height: 70rem;
    overflow-y: auto;
    padding-right: 3rem;
}

.modal-content h4 {
    font-size: 2.5rem;
    color: #fff;
    line-height: 2.7rem;
    margin-bottom: 3rem;
    font-weight: 700;
}

.about-video-sec img {
    margin-bottom: 2rem;
}

.welcome-overlay .modal-content, .finished-overlay .modal-content {
    background: transparent;
    box-shadow: none !important;
    border: none;
}

.welcome-overlay .modal-dialog {
    transform: translateY(-50%) !important;
    top: 50%;
}

.start-now-btn:active, .start-now-btn:focus {
    background-color: #DD8F41;
    outline: none;
    box-shadow: none !important;
}

.modal-content h1 {
    font-size: 4.7rem;
    color: #fff;
    font-weight: 100;
    line-height: 5.7rem;
    margin-bottom: 6rem;
}

.modal-content h2 {
    font-size: 4rem;
    color: #fff;
    font-weight: 100;
    line-height: 4.5rem;
    margin-bottom: 5rem;
}

.modal-content p {
    color: #fff;
    font-size: 2.9rem;
    line-height: 3.6rem;
    font-weight: 100;
    margin-bottom: 4rem;
}

.start-now-btn {
    width: 70%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DD8F41;
    border: none;
    font-size: 1.8rem;
    font-weight: 100;
    line-height: 2.3rem;
    border-radius: 5px;
    margin-top: 7rem;
    color: #fff;
}

.start-now-btn:hover {
    background-color: #DD8F41;
}

.video-play-modal .modal-dialog {
    max-width: 70%;
    margin: auto;
}

.video-play-modal .modal-dialog .modal-body:hover {
    border: none;
}

button.btn-close {
    width: 2.7rem;
    background-size: contain;
    height: 2.2rem;
    position: absolute;
    right: 2rem;
    top: 2rem;
    opacity: 1;
}

.video-play-modal .modal-dialog .modal-body {
    padding-bottom: 6rem;
    padding-top: 6rem;
}

.white-blur-bg {
    border-radius: 2.8rem;
    opacity: 1;
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    background-color: rgba(255, 255, 255, 0.6);
}

.second-popup {
    opacity: 1;
    backdrop-filter: blur(3rem);
    -webkit-backdrop-filter: blur(3rem);
    background-color: rgba(255, 255, 255, 0.6);
}

.second-popup .modal-dialog {
    max-width: 100% !important;
    margin: 0;
}

.second-popup .modal-dialog .modal-content {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.second-popup .modal-dialog .modal-content .modal-body img {
    width: 100%;
    height: 100%;
}

.second-popup button.btn-close {
    top: 0;
}

.video-play-modal .modal-dialog .modal-body:focus, .second-popup button.btn-close:focus {
    border: none;
    box-shadow: none;
    outline: none;
}

.print-popup-overlay .modal-content {
    background: #F0F0F0;
    border-radius: 1.5rem;
    border: none;
    padding: 4rem;
}

.print-popup-overlay .modal-dialog, .info-popup-overlay .modal-dialog, .payment-popup-overlay .modal-dialog {
    max-width: 50%;
    top: 50%;
    transform: translateY(-50%) !important;
    margin: auto;
}

.payment-popup-overlay .modal-dialog {
    max-width: 30%;
}

.print-popup-overlay .modal-content h1, .print-popup-overlay .modal-content p {
    color: #000000;
    margin-bottom: 2rem;
}

.download-btn, .print-btn {
    display: block;
    background-color: #fff;
    border: none;
    padding: 1rem 1.5rem;
    color: #000;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: Proxima Nova;
    margin-top: 3rem;
}

.print-popup-overlay .modal-content p {
    margin-bottom: 5rem;
}

.qis-content h5 {
    font-family: inherit;
    color: #fff;
    text-align: center;
    font-size: 2rem;
    line-height: 2.3rem;
    font-weight: 700;
    margin-bottom: 3rem;
}

.quote-image-sec img {
    width: 100%;
    height: auto;
}

.quote-image-sec {
    position: relative;
    margin-right: 3rem;
}

.qis-content {
    position: absolute;
    bottom: 2rem;
    left: 0;
    right: 0;
    text-align: center;
}

.qis-content img {
    width: auto;
}

.download-btn:hover, .print-btn:hover {
    background-color: #000;
    color: #fff;
}

/* review page css start from here */
.heading-section h1 {
    font-family: proxima-nova, sans-serif;
    font-size: 4.7rem;
    line-height: 5.7rem;
    font-weight: 100;
}

.print-popup-btn-sec a {
    font-size: 2.5rem;
    line-height: 3rem;
    font-family: inherit;
    font-weight: 700;
    color: #000;
    border: none;
}

.print-popup-btn-sec a img {
    margin-left: 1.5rem;
    width: 4.1rem;
}

.info-btn-sec img {
    width: 3.5rem;
}

.print-info-btn {
    height: auto;
    align-items: flex-start;
}

.info-btn-sec {
    padding-left: 1.5rem;
}

.total-estimate-sec h1 {
    font-family: proxima-nova, sans-serif;
    font-size: 4.7rem;
    line-height: 5.7rem;
    font-weight: 100;
}

.price-label {
    text-align: right;
    padding-right: 2rem;
}

.price-amount {
    text-align: right;
}

.total-estimate-sec p {
    font-size: 2rem;
    line-height: 2.4rem;
    font-family: proxima-nova, sans-serif;
    font-weight: 100;
}

.total-estimate-sec {
    display: none !important;
}

.review-page .total-estimate-sec {
    display: flex !important;
}

.info-popup-overlay h5 {
    font-size: 3.5rem;
    line-height: 4.2rem;
    text-align: center;
}

.info-popup-overlay h5 .modal-content {
    padding: 3rem;
}

.info-popup-overlay .modal-content .modal-body {
    min-height: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.request-form-overlay .modal-dialog {
    max-width: 50%;
    transform: translateY(-50%) !important;
    top: 50%;
}

.request-form-overlay .modal-content {
    background: #F0F0F0;
    padding: 0;
    border-radius: 1.5rem;
}

.request-form-overlay .modal-body {
    padding: 0;
}

.request-form-overlay p, .request-form-overlay h1 {
    color: #000;
}

.request-form-overlay .form-select {
    font-size: 2rem;
    line-height: 2.4rem;
    border: none;
    height: 5.4rem;
    padding: 0.5rem 2rem;
    margin-bottom: 3rem;
}

.request-form-overlay textarea {
    font-size: 2rem;
    line-height: 2.4rem;
    border: none;
    padding: 0.5rem 2rem;
    width: 100%;
}

.send-btn, .delete-btn {
    background-color: #DD8F41;
    border: none;
    padding: 1rem 1.5rem;
    color: #fff;
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-family: Proxima Nova;
    margin-top: 3rem;
    min-width: 18rem;
}

.send-btn:hover, .delete-btn:hover {
    background-color: black;
}

.rf-left-sec .quote-confirm-box {
    padding-right: 2.5rem !important;
}

.request-form-overlay .modal-content h1 {
    margin-bottom: 3rem;
}

.request-form-overlay .quote-confirm-box {
    width: auto;
    background-color: transparent;
}

.popup-request-form {
    padding-right: 3rem;
}

.request-form-delete h2 {
    color: #FF0000;
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 4.2rem;
}

.request-form-delete h2 span {
    font-weight: 300 !important;
    color: #000;
    margin-top: 3rem;
    display: block;
}

/* review page carousal css start from here */
.quote-confirm-box {
    width: 100%;
    background-color: #F0F0F0;
    border-radius: 15px;
}

.qc-header {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 2rem;
}

.quote-confirm-box h3 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: 0;
}

.qc-des {
    padding: 1.5rem 2rem;
}

.qc-des p {
    font-size: 2rem;
    line-height: 2.3rem;
    font-family: inherit;
}

ul.qc-list li {
    font-size: 2rem;
    line-height: 2.3rem;
    font-family: inherit;
}

ul.qc-list {
    padding-left: 2.6rem;
}

.qc-button .btn {
    background: #B3DADB;
    border-radius: .5rem;
    color: #FFFFFF;
    font-size: 1.8rem;
    line-height: initial;
    padding: .5rem 1.5rem;
    height: 3.5rem;
    border: none;
}

.qc-button .btn-confirm {
    background-color: #DD8F41;
}

.btn-confirm.active {
    background-color: #62BBBC;
}

.quote-confirm-sec {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.qc-button .btn + .btn {
    margin-left: 1rem;
}

.btn-delete {
    background-color: #E2E0E0 !important;
    height: 3.5rem !important;
    width: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    padding: 0.5rem !important;
    min-width: 3.5rem !important;
}

.btn-delete img {
    width: 1.6rem !important;
}

.qc-button {
    display: flex;
    flex-direction: row;
    margin: 1.5rem 0;
}

.qc-button .btn:active, .qc-button .btn:hover, .qc-button .btn:focus {
    border: none;
    box-shadow: none;
    outline: none;
}

.review-carousel.owl-theme .owl-nav button.owl-prev:hover, .review-carousel.owl-theme .owl-nav button.owl-next:hover {
    background-color: transparent;
}

/* review page owl-carousal slider css start from here */
.owl-carousel .owl-nav button.owl-prev .review-prv-img, .owl-carousel .owl-nav button.owl-next .review-nxt-img {
    display: block !important;
}

.review-carousel.owl-theme .owl-nav {
    text-align: left;
    margin-top: 0;
    display: flex;
    align-items: center;
}

.review-carousel {
        padding-left: 8%;
        margin-left: .7rem;
}


.review-carousel.owl-theme .owl-nav .owl-dots {
    margin: 0;
    background-color: transparent;
}

.review-carousel.owl-theme .owl-nav .owl-dots .owl-dot {
    background: transparent;
}

.review-carousel.owl-theme .owl-nav .owl-dots .owl-dot span {
    background-color: #000;
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 100%;
    opacity: .49;
}

.review-carousel.owl-theme .owl-nav .owl-dots .owl-dot.active span {
    opacity: 1;
}

.review-carousel.owl-theme .owl-nav button.owl-prev, .review-carousel.owl-theme .owl-nav button.owl-next {
    margin: 0;
}

/* terms & sign page css start from here */
.terms-page-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #707070;
    border-radius: 30px;
    height: 60rem;
    padding: 3rem 4rem;
}

.terms-page-content p {
    font-size: 2.5rem;
    line-height: 3.7rem;
    color: #8A8A8A;
    margin-bottom: 3rem;
}

.tpc-scroll-area {
    overflow-y: auto;
    height: 100%;
    padding: 1.5rem;
}

.signature-sec .nav-link.active {
    color: #62BBBC;
}

.signature-sec .nav-tabs {
    border: none;
}

.signature-sec .nav-link {
    font-size: 3rem;
    line-height: 3.7rem;
    color: #8A8A8A;
    border: none;
}

.tab-content {
    border: solid 1px #707070;
    border-radius: .5rem;
    margin-top: 1px;
    padding: 2.5rem;
    text-align: center;
    height: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signature-sec .nav-link {
    padding-left: 0;
}

.signature-sec .nav-link + .nav-link {
    padding: 2rem;
}

.accept-sign-btn {
    background: #DD8F41;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 2.8rem;
    line-height: 3.3rem;
    padding: .5rem 1.5rem;
    height: 5.5rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 22rem;
    border-radius: 5rem;
}

.signature-draw {
    border-bottom: 2px solid #707070;
    margin-bottom: 3rem;
}

.signature-draw h1 {
    font-family: Lamar Pen;
    font-size: 4.7rem;
    line-height: 5.5rem;
    transform: rotate(-5deg);
    padding-bottom: 0rem;
}

.accept-sign-btn:hover {
    background-color: #000;
    color: #fff;
}

.tab-content .tab-pane {
    width: 100%;
}

.customer-review-sec h3 {
    font-size: 2.5rem;
    line-height: 3rem;
    font-family: inherit;
    font-weight: 700;
}

/* payments page css start from here */
table.payment-stages tr {
    vertical-align: baseline;
}

table.payment-stages tr th {
    color: #000;
    line-height: 2.5rem;
    padding: 1rem 0;
}

table.payment-stages tr td {
    color: #040404;
    opacity: .27;
    line-height: 2.5rem;
    padding: 1rem 0;
}

table.payment-stages tr th span, table.payment-stages tr td span {
    font-size: 1.7rem;
}

table.payment-stages tr th:last-child, table.payment-stages tr td:last-child {
    font-size: 2rem;
    padding-left: 1rem;
}

table.payment-stages tr th:first-child, table.payment-stages tr td:first-child {
    padding-right: 6rem;
}

.payment-count-area p {
    margin-bottom: 3rem;
    font-weight: 100;
}

.total-price-box {
    background-color: #000000;
    border-radius: 1.5rem;
    text-align: center;
    width: 18.5rem;
}

.total-price-box h3 {
    font-size: 2.9rem;
    line-height: 3.6rem;
    color: #fff;
    margin: 0;
    padding: 1.5rem;
}

.total-price-box span {
    font-size: 2rem;
    line-height: 2.4rem;
}

.total-price-box h3 + h3 {
    border-top: 1px solid #AFAFAF;
}

.card-sec {
    background-color: #F0F0F0;
    padding: 1.5rem 2.5rem;
    border-radius: 15px;
    height: 10.6rem;
    margin-bottom: 1.5rem;
}

.card-sec a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
    height: 100%;
}

.card-sec h3 {
    font-size: 2.9rem;
    line-height: 3.6rem;
    font-weight: 100;
    margin-bottom: 0;
}

.card-sec img {max-width: 11rem;max-height: 5rem;}

/* progress page css start from here */
.notifications-sec > h3, .contact-form-sec > h3 {
    margin-bottom: 2rem;
}

.notifications-sec ul {
    max-height: 40rem;
    overflow-y: auto;
    padding: 0rem 1.5rem;
}

.notifications-sec ul li h3 {
    font-weight: 100;
}

.contact-form-sec textarea {
    width: 100%;
}

.contact-form-sec select, .contact-form-sec textarea {
    background: #F6F6F6;
    border: none;
    padding: 1rem 2rem;
    font-size: 2rem;
    line-height: 2.4rem;
}

.contact-form-sec textarea {
    margin-top: 2rem;
}

.contact-form-sec .send-btn {
    border-radius: 10rem;
    font-size: 1.8rem;
    line-height: 2.3rem;
}

/* about us css started from here */
.about-us-page .main-content {
    height: auto;
}


.about-detail-sec p {
    font-weight: 100;
}

.award-wrapper {
    width: 90%;
    height: 25rem;
    background-color: #F7F7F7;
    border-radius: 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 3rem;
}

/* vertical owl carousel css start from here */
.vertical-carousel .slick-prev:before, .vertical-carousel .slick-next:before {
    display: none;
}

.vertical-carousel .slick-prev {
    left: 0;
    top: calc(100% + 2rem);
    transform: rotate(-90deg);
}

.vertical-carousel .slick-next {
    left: 25px;
    top: calc(100% + 2rem);
    transform: rotate(90deg);
}

.vertical-carousel .slick-slide img {
    margin-bottom: .5rem;
}

.chatbot-icon svg {
    padding: 1rem;
}

/* yes-start css start */
.yes-start-screen {
    position: fixed;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    left:0;
}

.yes-start-screen a {
    text-decoration: none;
    font-size: 2.2rem;
    color: #fff;
    font-weight: 100;
    line-height: 2.7rem;
    text-transform: uppercase;
    text-align: center;
}

.yss-brand {
    position: fixed;
    bottom: 5rem;
    left: auto;
    right: auto;
    opacity: 0;
        transition: .9s ease-in-out;
}


.yss-lottie-ani {
    position: absolute;
    bottom: 50%;
    transition: .9s ease-in-out;
    opacity: 0;
}

.yss-first-quote {
    position: absolute;
    bottom: 50%;
    z-index: 2;
     transition: .9s ease-in-out;
}

.yss-welcome-message {
    position: absolute;
    top: 0%;
    transition: .7s ease-in-out;
    opacity: 0;
}
.hide-wrapper {
    opacity: 0;
    transition: opacity .9s;
}

.optn-btn:active, .optn-btn:focus {
    background: #DD8F41;
    border-color: #DD8F41;
    outline: none;
    box-shadow: none !important;
}

.modal.second-popup.fade .modal-dialog {
    transition: transform .9s ease-out;
    transform: translate(0,0%) !important;
}
.modal.second-popup.show .modal-dialog {
    transform: none;
}

.modal.video-play-modal.fade .modal-dialog, .modal.videos-slider-popup-overlay.fade .modal-dialog {
    transition: transform .9s ease-out;
    transform: translate(0,100%);
}
.modal.video-play-modal.show .modal-dialog, .modal.videos-slider-popup-overlay.show .modal-dialog {
    transform: none;
}

.card-sec:hover {
    background: #DD8F41;
}

.card-sec:hover a {
    color: #fff;
}

.card-sec:hover img {
    filter: invert(1);
}

form.payment-form label {
    font-size: 2rem;
    font-family: proxima-nova, sans-serif;
    color: #505050;
    margin-bottom: .5rem;
}

form.payment-form input.form-control, form.payment-form select.form-select {
    border: none;
    font-size: 2rem;
    color: #AAAAAA;
    padding: 1rem 1.5rem;
}

form.payment-form .input-group {
    border: solid 1px #707070;
}

.paymentpp-btn {
    background: #DD8F41;
    border: solid 1px #707070;
    color: #fff;
    width: 100%;
    border-radius: .5rem;
        padding: 1rem;
}

.paymentpp-btn:hover {
    background: #707070;
    box-shadow: none;
}

.paymentpp-btn:active, .paymentpp-btn:focus {
    outline: none;
    box-shadow: none !important;
    border: none;
}
form.payment-form {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 4rem;
}

.blk-logo {
    padding: 4rem;
}

.payment-popup-overlay .modal-content {
    border-radius: 3rem;
}

.progress-step-active h5 {
    color: #62BBBC;
}
.progress-step-active .inactive h5{
color: #000;
}


.progress-step-main h5 {
    text-align: center;
    margin-top: 1.5rem;
}

.inactive img {
    opacity: 0.3;
}

.progress-step-main .step {
    text-align: center;
}


.position-with-animation .inactive circle {fill: #CCE8E9;}


.cta-main {
    position: relative;
}

.cta-top-border, .cta-bottom-border {
    position: absolute;
    width: 0%;
    height: 2px;
    background: red;
    right: 0;
    transition: .6s width;
}

.cta-top-border {
    top: 0;
}

.cta-bottom-border {
    bottom: 0;
}

.cta-main:hover .cta-top-border, .cta-main:hover .cta-bottom-border{
     transition: .6s width;
     width: 100%;
}

.request-form-overlay textarea:focus-visible {
    outline: none;
}
.info-btn-sec a {
    border: none;
}




/* responsive css start from here */

/* Small devices (landscape phones, 576px and up) */
@media (min-width:576px) {
    .active.aos-init.aos-animate {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;

}

   .position-without-animation .step-two, .position-without-animation .step-three, .position-without-animation .step-four {
        position: absolute;
        left: 0%;
        transition: ease-in 1s !important;
    }

    .position-with-animation .active.step-two {
        position: absolute;
        left: 11.11%;
        transition: ease-in 1s !important;
        opacity: 1;
    }

    .position-with-animation .active.step-three {
        position: absolute;
        left: 22.22%;
        transition: ease-in 1s !important;
        opacity: 1;
    }

    .position-with-animation .active.step-four {
        position: absolute;
        left: 33.33%;
        transition: ease-in 1s !important;
        opacity: 1;
    }
        .position-with-animation .active.step-five {
        position: absolute;
        left: 44.44%;
        transition: ease-in 1s !important;
        opacity: 1;
    }

        .position-with-animation .active.step-six {
        position: absolute;
        left: 55.55%;
        transition: ease-in 1s !important;
        opacity: 1;
    }
        .position-with-animation .active.step-seven {
        position: absolute;
        left: 66.66%;
        transition: ease-in 1s !important;
        opacity: 1;
    }

        .position-with-animation .active.step-eight {
        position: absolute;
        left: 77.77%;
        transition: ease-in 1s !important;
        opacity: 1;
    }


    .position-with-animation .active.delivery-truck{
        width: 22.22%;
    }

    .position-with-animation .active.delivery-truck:after{
        content: "";
        width: 7.5rem;
        height: 5rem;
        right: 0;
        top: 10px;
        position: absolute;
        background: url('../images/delivery_van.svg');
        background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    }


    .position-with-animation .active.delivery-truck .step-inner {
    width: calc(100% / 2);
}


       .position-with-animation .inactive.step-one {
        position: absolute;
        right: 77.77%;
        transition: ease-in 1s;
        opacity: 1;
    }


       .position-with-animation .inactive.step-two {
        position: absolute;
        right: 66.66%;
        transition: ease-in 1s;
        opacity: 1;
    }


       .position-with-animation .inactive.step-three {
        position: absolute;
        right: 55.55%;
        transition: ease-in 1s;
        opacity: 1;
    }


       .position-with-animation .inactive.step-four {
        position: absolute;
        right: 44.44%;
        transition: ease-in 1s;
        opacity: 1;
    }

       .position-with-animation .inactive.step-five {
        position: absolute;
        right: 33.33%;
        transition: ease-in 1s;
        opacity: 1;
    }

    .position-with-animation .inactive.step-six {
        position: absolute;
        right: 22.22%;
        transition: ease-in 1s;
        opacity: 1;
    }

        .position-with-animation .inactive.step-seven {
        position: absolute;
        right: 11.11%;
        transition: ease-in 1s;
        opacity: 1;
    }

    .position-with-animation .inactive.step-eight {
        position: absolute;
        right: 0%;
        transition: ease-in 1s;
        opacity: 1;
    }

    .position-with-animation .step-nine {
        position: absolute;
        right: 88.88%;
        transition: ease-in 1s;
        opacity: 1;
    }

.position-with-animation .active.delivery-truck:before {
    position: absolute;
    content: "";
    width: 50%;
    height: 1px;
    background: #62bbbc;
    top: 25px;
    left: 30%;
    z-index: -1;
}

.progress-step-main .step.active .step-inner:after {
    position: absolute;
    content: "";
    width: 120%;
    height: 1px;
    background: #62bbbc;
    top: 25px;
    left: 80%;
    z-index: -1;
}

.progress-step-main .step .step-inner {
    position: relative;
}

.progress-step-active:before {
    background: #62BBBC;
    position: absolute;
    content: "";
    height: 1px;
    width: 90%;
    top: 25px;
    right: 39px;
     z-index: -1;
     opacity: .19;
}
.progress-step-active {
    width: 100%;
}

}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width:768px) {

    /* body {
      height: 100vh;
      overflow-y: hidden;
    } */
    .navbar-expand-md, .navbar-expand-md .navbar-nav {
        flex-direction: column;
    }

    .side-bar {
        position: fixed;
        width: 25%;
    }

 .main-content {
    height: 100%;
    min-height: 100vh;
    padding-bottom: 100px !important;
}

    .side-bar .navbar {
        height: 100vh;
        position: relative;
    }

    .side-bar .navbar .chatbot-sec {
    position: absolute;
    left: 50%;
    bottom: 0rem;
    transform: translateX(-50%);
    }
    .footer-section .chatbot-sec {
    display: none !important;
}


    .glass-page .main-content .container {
    padding-left: 0;
    padding-right: 0;
}

.welcome-overlay .modal-dialog {
    max-width: 50%;
}

.finished-overlay .modal-dialog {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10rem;
}

.payment-popup-overlay .modal-dialog {
    max-width: 30%;
}
.footer-section {
    overflow: hidden;
}

.about-us-page .footer-section {
    background-color: #fff;
    bottom: -100px;
}

}

/* Large devices (desktops, 992px and up) */
@media (min-width:992px) {

    /* team page custom animation css */
    .width-without-animation .team-member {
        opacity: 0;
        transition: opacity ease-in 1s;
    }

    .width-without-animation .second-tm, .width-without-animation .third-tm, .width-without-animation .fourth-tm {
        position: absolute;
        left: 0%;
        transition: ease-in 1s;
    }

     

    .width-with-animation .second-tm {
        position: absolute;
        left: 25%;
        transition: ease-in 1s;
        opacity: 1;
    }

    .width-with-animation .third-tm {
        position: absolute;
        left: 50%;
        transition: ease-in 1s;
        opacity: 1;
    }

    .width-with-animation .fourth-tm {
        position: absolute;
        left: 75%;
        transition: ease-in 1s;
        opacity: 1;
    }

}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}

@media only screen and (max-width:1366px) {
    html {
        font-size: 40%;
    }

    img.brand-logo {
        width: auto;
        max-width: 80%;
    }

    .navbar-nav li a img {
        width: auto;
        margin: auto;
        height: 26px;
    }

    /* safari-only css*/

@media not all and (min-resolution: 0.001dpcm) {

 .estimate-content-section {width: 80%;}
.contact-no, .cta-btn .btn {padding-right: 10px;padding-left: 10px;}
.contact-no{height: 70px; border-radius: 20px}
.cta-btn, .popup-cta-btn {height: 70px;width: 220px; border-radius: 20px}
.popup-cta-btn img {width: 40px;}

.pagination-sec {border-radius: 20px;}
.chatbot-icon {width: 60px;height: 60px;}
.chatbot-icon svg {padding: 4px;}
 .qc-button .btn {
    padding: .5rem 0.5rem;
    align-items: center;
    display: flex;
}
.card-sec img {
    max-width: 75px;
}

}

}

@media (max-width: 1199px) {
        .card-sec img {
    width: 8rem;
}

    .card-sec h3 {font-size: 2.4rem;
    line-height: 2.8rem;}
}

@media (max-width: 1024px) {
.estimate-content-section {
    width: 75rem;
    height: 60rem;
}
.total-estimate-sec h1 {
    font-size: 3.7rem;
    line-height: 4.7rem;
}

.main-content .container, .main-content .container-fluid, .footer-section .container {
    max-width: 94%;
}
.contact-no {
    padding-left: 2rem;
    padding-right: 2rem;
}
    .review-carousel {
        padding-left: 0;
    }
    .terms-page-content {
    padding: 3rem 2rem;
}

a.navbar-brand {
    margin-bottom: 0rem;
}

.navbar-nav li a {
    margin-bottom: 0.5rem;
}

.side-bar .navbar .chatbot-sec {
    padding: 1.5rem 0 !important;
}

.ecs-scrolling-description-sec {
    height: 34rem;
}

.main-content .container, .main-content .container-fluid {
    padding-left: 0rem;
    padding-right: 0rem;
}
.payment-popup-overlay .modal-dialog {
    max-width: 50%;
}

}


@media (max-width: 991px) {

.step-img svg {
    width: 5rem;
}
.progress-step-main h5 {
    font-size: 1.8rem;
    line-height: 2rem;
    margin-top: 0;
}
.progress-step-main .step.active .step-inner:after {
    width: 110%;
}
.total-price-box, .estimate-content-section {
    width: 100%;
}

.pagination-sec {
    width: 7.5rem;
    padding: 2rem 0.5rem;
}
  .rgt-sec {
    display: flex;
    justify-content: space-between;
}

.rgt-sec h3 {
    margin-bottom: 0;
}

.estimate-right-sec {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.total-estimate {
    padding: 1rem 2rem;
}
.total-btn {
    font-size: 2rem;
    line-height: 2.4rem;
}
.brand-list {
    margin: 3rem 0;
}
.brand-list img {
    width: 7rem;
}
.qc-button .btn {
    padding: .5rem 1rem;
}
.total-estimate-sec h1 {
    font-size: 3.2rem;
    line-height: 3.4rem;
}
.member-profile-pic {
    height: 15.2rem;
    width: 15.2rem;
}
.team-member-contacts img {
    width: 20px;
}

.contact-no h3 {
    font-size: 2.8rem;
    line-height: 3.5rem;
}
.ecs-scrolling-description-sec {
    height: 28rem;
}

}

@media only screen and (max-width:767px) {

    .main-content .container, .main-content .container-fluid, .footer-section .container {
    padding-left: 2rem;
    padding-right: 2rem;
}

.main-content .container, .footer-section .container {
    max-width: 100%;
}

    a.navbar-brand {
        text-align: left;
        margin-bottom: 0;
        margin-left: 15px;
        padding-left: 0;
        padding-right: 0;
    }

    button.navbar-toggler {
        margin-right: 1.5rem;
    }

    .navbar-expand-md .navbar-collapse {
        background-color: #212529;
        padding: 1.5rem;
        border-top: 1px solid #262626;
        background-color: #000;
    }

    .print-popup-overlay .modal-dialog, .info-popup-overlay .modal-dialog {
        max-width: 90%;
    }
    .request-form-overlay .modal-dialog {
    max-width: 90%;
}

    .main-content .container {
        max-width: 100%;
        margin: auto;
    }

    .extra-btn-sec {
        position: unset;
        display: flex;
        align-items: center;
        margin-top: 3rem;
        justify-content: space-between;
    }

    .pagination-sec {
        position: unset;
        width: calc(100% - 32rem);
        height: 11rem;
        padding: 1rem;
    }

    .pagination-sec ul.pagination-list {
        display: inline-flex;
    }

    .pagination-sec ul.pagination-list li {
        padding-bottom: 0 !important;
    }

    .estimate-content-section {
        width: 100%;
        margin-bottom: 3rem;
        height: 100%;
    }

    .ecs-scrolling-description-sec {
        height: auto;
    }

    .main-content {
        min-height: 100vh;
        padding-bottom: 17rem !important;
        padding-top: 17rem !important;
        height: 100%;
    }

    .side-bar {
        position: fixed;
        z-index: 1050;
        width: 100%;
    }

    .footer-section, .review-page .total-estimate-sec {
        backdrop-filter: blur(3rem);
        -webkit-backdrop-filter: blur(3rem);
        background-color: rgba(255, 255, 255, 0.8);
    }

    .side-bar .navbar .chatbot-sec {
        display: none !important;
    }

    .chatbot-icon img {
        padding: 1.5rem;
    }

    .chatbot-icon svg {
        padding: .5rem;
    }

    .cta-btn .btn {
        padding: 2rem 2rem;
        border-radius: 1.5rem;
    }

    .cta-btn {
        background-color: transparent;
        backdrop-filter: unset;
    }

    .pagination-sec ul.pagination-list li a {
        width: 5rem;
        height: 5rem;
        font-size: 2.6rem;
    }

    .pagination-sec ul.pagination-list li + li {
        padding-left: 1rem;
    }

    .chatbot-icon {
        background-color: #000;
    }

    .chatbot-icon g#Icon_ionic-ios-chatbubbles {
        fill: #fff;
    }

    .chatbot-icon g#Icon_ionic-ios-chatbubbles {
    }

    .team-page .footer-section .cta-btn {
        justify-content: flex-end;
    }

    .terms-page-content {
        margin-bottom: 3rem;
    }

    .footer-section {
        position: fixed;
        z-index: 1020;
    }

    .print-info-btn {
        width: auto;
    }

    table.payment-stages tr th:first-child, table.payment-stages tr td:first-child {
        padding-right: 2rem;
    }

    .total-price-box {
        display: flex;
        width: 100%;
        margin-top: 3rem;
    }

    .total-price-box h3 {
        width: 50%;
    }

    .total-price-box h3 + h3 {
        border-left: 1px solid #AFAFAF;
        border-top: none;
    }


    .review-carousel.owl-theme .owl-nav {
        justify-content: center;
    }

    .review-carousel.owl-theme .owl-nav > button .review-prv-img, .review-carousel.owl-theme .owl-nav > button .review-nxt-img {
        display: none !important;
    }

    .review-carousel.owl-theme .owl-nav .owl-dots .owl-dot {
        margin: 0;
    }

    .review-page .total-estimate-sec {
        position: absolute;
        bottom: 100%;
        justify-content: center;
        left: 0;
    }

    .review-page .main-content {
        padding-bottom: 25rem !important;
    }

    .cta-btn, .popup-cta-btn, .pagination-sec {
        height: 9rem;
    }
  
    .pagination-scrollable {
    display: flex;
    align-items: center;
}

.popup-cta-btn img {
    width: 5rem;
}

.finished-overlay .modal-dialog, .request-form-overlay .modal-dialog {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10rem;
}
.total-estimate-sec h1 {
    font-size: 4.2rem;
    line-height: 4.4rem;
}

.print-popup-overlay .modal-dialog, .info-popup-overlay .modal-dialog, .payment-popup-overlay .modal-dialog {
    max-width: 90%;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.request-form-overlay .quote-confirm-box {
    padding-right: 0 !important;
}

.popup-request-form {
    padding-left: 2rem;
    padding-bottom: 2rem;
}

.video-play-modal .modal-dialog {
    max-width: 90%;
}

.payment-popup-overlay .modal-dialog {
    max-width: 70%;
}

.ecs-scrolling-description-sec {
    margin-right: 0;
}

    /* desable aos animation css */
    .aos-animate {
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }

    .inactive.aos-init.aos-animate {
    -o-transition-property: inherit !important;
    -moz-transition-property: inherit !important;
    -ms-transition-property: inherit !important;
    -webkit-transition-property: inherit !important;
    transition-property: inherit !important;
    -o-transform: inherit !important;
    -moz-transform: inherit !important;
    -ms-transform: inherit !important;
    -webkit-transform: inherit !important;
    transform: inherit !important;
    -webkit-animation: inherit !important;
    -moz-animation: inherit !important;
    -o-animation: inherit !important;
    -ms-animation: inherit !important;
    animation: inherit !important;
}

}

@media only screen and (max-width: 575px) {
    .qc-des {
    padding: 1.5rem 1rem;
}
.print-popup-btn-sec a {
    font-size: 2rem;
    line-height: 3rem;
}

.print-popup-btn-sec a img {
    margin-left: .5rem;
    width: 3.5rem;
}

.info-btn-sec a img{
    width: 3.5rem;
}

.quote-image-sec {
    margin-right: 0;
    margin-bottom: 3rem;
}
.start-now-btn {
    margin-top: 0rem;
    margin-bottom: 4rem;
}

.modal-content p {
    margin-bottom: 2rem;
}

.modal-content h1 {
    margin-bottom: 2rem;
}

.contact-no, .cta-btn, .popup-cta-btn, .pagination-sec, .estimate-content-section {
    border-radius: 1.8rem;
}

.step-inner {
    position: relative;
}

.progress-step-main .step.active .step-inner:after {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background: #62bbbc;
    top: 25px;
    left: 50%;
    z-index: -1;
}

.progress-step-active:before {
    background: #62BBBC;
    position: absolute;
    content: "";
    height: 90%;
    width: 1px;
    top: 25px;
    right: calc(50% - 1px);
    z-index: -1;
    opacity: .19;
}

.progress-step-main h5 {
    background: white;
}

.welcome-overlay .modal-dialog, .print-popup-overlay .modal-dialog, .info-popup-overlay .modal-dialog, .payment-popup-overlay .modal-dialog {
    top: 0;
    transform: none !important;
}

.active.aos-init.aos-animate {
    -o-transition-property: inherit !important;
    -moz-transition-property: inherit !important;
    -ms-transition-property: inherit !important;
    -webkit-transition-property: inherit !important;
    transition-property: inherit !important;
}

}

@media only screen and (max-width: 479px) {
    .qc-button {
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: -2rem;
}
    .qc-button .btn {
    font-size: 2rem;
    padding: .5rem 1.5rem;
    height: 4.5rem;
    margin-left: 1rem;
}
.qc-button .btn-confirm {
    display: flex;
    width: 100%;
    margin-left: 0 !important;
    margin-top: 2rem;
    border-radius: 0px 0px 5px 5px;
    align-items: center;
    justify-content: center;
}
.btn-delete {
    height: 4.5rem !important;
    width: 4.5rem !important;
}

}


@media only screen and (max-width:428px) {
    .chatbot-icon svg {
        padding: 1rem;
    }

    .vertical-carousel .slick-next {
        left: 5rem;
    }

    .heading-section h1 {
        font-size: 3.6rem;
        line-height: 3.8rem;
    }

    .info-btn-sec {
        padding-left: 0rem;
    }
    .pagination-sec {
    width: calc(100% - 25rem);
}

.glass-page .popup-cta-btn {
    width: 20rem !important;
}

.popup-cta-btn.print-info-btn img {
    width: 3rem !important;
}

form.payment-form {
    padding-bottom: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

.second-popup button.btn-close {
    top: -15px;
}

  /* safari-only css*/

@media not all and (min-resolution: 0.001dpcm) {
.button-lottie-ani>div {
width: 80px!important;
}
 .chatbot-icon {
    width: 50px;
    height: 50px;
}


}

}